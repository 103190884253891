<template>
	<div class="page">
		<!-- 顶部菜单 -->
		<topBar :keyword="keyword"></topBar>
		<!-- 内容 -->
		<div class="block20"></div>
		<div class="w1240 relative xc_ccategory_box">
			<div class="left_box">
				<!-- <div class="itemlist">
					<div class="titlename">
						<span></span>
					</div>
				</div> -->
				<el-collapse v-model="activeNames">
					<el-collapse-item v-for="(item,index) in menuList" :key="index" :name="index" :title="item.title">
						<div class="itemlist">
							<div class="itemobj" v-for="(titem,secIndex) in item.sonType" :key="secIndex+100"
								@click="handleChange(index,secIndex)">
								<span :class="{'active':setformobj.typeSubId==titem.id}">{{titem.title}}</span>
							</div>
						</div>
					</el-collapse-item>
				</el-collapse>
			</div>
			<div class="right_box">
				<div class="right_top">
					<div class="label_box">
						<!-- div class="back_all" @click="clickbackall()">返回所有商品</div> -->
						<div class="labeldiv">分类选择：</div>
						<div class="valuediv">
							
							<div class="text1" :class="{'clickbackall':typeSubIndex!=null}" @click="clickbackall()">全部分类</div>
							<div class="mixicon iconfont icon-qianjin" v-if="typeIndex!=null"></div>
							<div class="text1" v-if="typeIndex!=null">{{menuList[typeIndex].title}}</div>
							<div class="mixicon iconfont icon-qianjin" v-if="typeSubIndex!=null"></div>
							<div class="text2" v-if="typeSubIndex!=null">
								{{menuList[typeIndex].sonType[typeSubIndex].title}}</div>
						</div>
						<!-- <div class="inputdiv">
						  <el-input placeholder="请输入商品名/品牌名/厂家名称" v-model="keyword" class="input">
						     <el-button slot="append" icon="el-icon-search"></el-button>
						  </el-input>
						</div> -->
					</div>
					<div class="border_line"></div>
					<div class="label_box" v-if="isLogin">

						<div class="labeldiv">价格：</div>
						<div class="pricelist">
							<div class="itemobj" v-for="(item,index) in priceList" :key="index">
								<span :class="{'active':setformobj.priceIndex==index}"
									@click="handlePricelist(index,item.min,item.max)">{{item.name}}</span>
							</div>
							<div class="formitem">
								<el-col :span="8">
									<el-input type="number" placeholder="最小价格" v-model="setformobj.minPrice"></el-input>
								</el-col>
								<el-col class="line" :span="2">-</el-col>
								<el-col :span="8">
									<el-input type="number" placeholder="最高价格" v-model="setformobj.maxPrice"></el-input>
								</el-col>
								<el-button plain style="margin-left: 10px;" @click="priceSearch">搜索</el-button>
							</div>
						</div>
					</div>
					<div class="border_line"></div>
					<div class="label_box">
						<div class="labeldiv">标签：</div>
						<div class="pricelist">
							<div class="itemobj" v-for="(item,index) in tagList" :key="index">
								<span @click="selectTags(index)" :class="{'active':item.isselect}">{{item.name}}</span>
							</div>
						</div>
					</div>
					<div class="border_line"></div>
					<div class="label_box1">
						<div class="labeldiv">品牌：</div>
						<div class="datalist">
							<div class="itemobj" v-for="(item,index) in brandList" :key="index">
								<span @click="selectBrand(item.id)"
									:class="{'active':item.id==setformobj.brand}">{{item.title}}</span>
							</div>
						</div>
					</div>
					<div class="border_line"></div>
					<div class="label_box1">
						<div class="labeldiv">厂家：</div>
						<div class="datalist">
							<div class="itemobj" v-for="(item,index) in factoryList" :key="index">
								<span @click="selectFactory(item.id)"
									:class="{'active':item.id==setformobj.manufacturer}">{{item.title}}</span>
							</div>
						</div>
					</div>
				</div>
				<!-- 中间按钮 -->
				<div class="block20"></div>
				<div class="right_center">
					<div class="itemobj" @click="handleSort(0)" :class="{'active':0==setformobj.sortIndex}">
						<span>综合排序</span></div>
					<div class="itemobj" @click="handleSort(1)" :class="{'active':1==setformobj.sortIndex}">
						<span>销量</span></div>
					<div class="itemobj" @click="handleSort(2)"
						:class="{'active':2==setformobj.sortIndex || 3==setformobj.sortIndex}" v-if="isLogin">
						<span>价格</span>
						<span class="iconfont triangle icon-icon-"
							:class="{'triangle_sheng':3==setformobj.sortIndex}"></span>
					</div>
					<!-- <div class="itemobj"><span>上架时间</span></div> -->
					<div class="textobj">共{{total}}件<span
							v-if="typeSubIndex!=null">{{menuList[typeIndex].sonType[typeSubIndex].title}}</span>相关产品
					</div>
				</div>
				<!-- 中间按钮 end -->
				<!-- 商品列表 -->
				<div class="block20"></div>
				<div class="xc_goodslist_box right_bottom">
					<div class="datalist">
						<div class="item" v-for="(item, index) in searchList" :key="index"
							@click="clickgoodsDetail(item.id,'goods')">
							<div class="img"><img :src="item.avatar" /></div>
							<div class="conbox">
								<div class="title clamp2" v-html="item.goodsName"></div>

								<div class="block20"></div>

								<div class="pricebox" v-if="isLogin">
									<div class="nowprice">￥{{item.finalPrice}}</div>
									<div class="oldprice" v-if="item.oriPrice">¥{{item.oriPrice}}</div>
									<div class="volume">{{item.supervision_class}}</div>
									<div class="volume">月销{{item.salesVolume}}笔</div>
								</div>
								<div class="pricebox" v-if="!isLogin">
									<div class="nowprice">￥登录可见</div>
									
									<div class="volume">月销{{item.salesVolume}}笔</div>
									<div class="volume"><span class=" badge badge-info">{{item.supervision_class}}</span></div>

								</div>

							</div>
						</div>
					</div>
				</div>
				<div class="block20"></div>
				<div class="pagination_box">
					<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
						:page-size="pageSize" layout="prev, pager, next, jumper" :total="total" background>
					</el-pagination>
				</div>
				<!-- 商品列表 end -->
			</div>
		</div>

		<div class="block40"></div>
	</div>
</template>

<script>
	
	import {
		mapState
	} from 'vuex'
	import topBar from "../components/topBar/topBar.vue";
	export default {
		components: {
			topBar
		},
		data() {
			return {
				type: "", //来源
				currentPage: 1,
				pageSize: 16,
				total: 1,
				searchList: [],
				keyword: "",
				activeNames: [],
				typeIndex: null,
				typeSubIndex: null,
				menuList: [],
				priceList: [{
						name: "0-50",
						min: 0,
						max: 50
					},
					{
						name: "50-100",
						min: 50,
						max: 100
					},
					{
						name: "100-200",
						min: 100,
						max: 200
					},
					{
						name: "200以上",
						min: 200,
						max: 'max'
					}
				],
				tagList: [{
						name: "推荐",
						isselect: false,
					},
					{
						name: "热销",
						isselect: false,
					},
					{
						name: "秒杀",
						isselect: false,
					},
					{
						name: "爆品",
						isselect: false,
					},
					{
						name: "新品",
						isselect: false,
					},
					{
						name: "特价",
						isselect: false,
					}
				],
				factoryList: [],
				brandList: [], //品牌
				setformobj: {
					manufacturer: null, //厂家选择ID
					brand: null, //品牌id
					tags: [], //标签
					priceIndex: null, //价格下标
					maxPrice: null, //最高价
					minPrice: null, //最低价
					//typeId:null,
					typeSubId: null,
					sortIndex: 0, //综合排序 1是销量 2是价格降序，3是升序
				},
				supervision_class: ['乙类OTC', '甲类OTC', 'RX处方药', '一类器械', '二类器械', '三类器械', '非监管器械', '保健品', '食品', '其他']

			};
		},
		mounted: function() {
			console.log(this.$route.query)
			if (this.$route.query) {
				if (this.$route.query.type) {
					this.type = this.$route.query.type
				}
				//有没有带关键词
				if (this.$route.query.keyword) {
					this.keyword = this.$route.query.keyword
				}
				if (this.$route.query.brandId) {
					this.setformobj.brand = Number(this.$route.query.brandId)
				}
				//if(this.$route.query.typeId){
				//	this.setformobj.typeId = Number(this.$route.query.typeId)
				//}
				if (this.$route.query.typeSubId) {
					this.setformobj.typeSubId = Number(this.$route.query.typeSubId)
				}
			}
			this.getSecondPage()
			this.getBrandPage()
			this.getfactoryPage()
			this.getsupervision()
		},
		computed: {
			...mapState(['userInfo', 'isLogin', 'bsnName']),
		},
		methods: {
			clickbackall(){
				window.location.reload();
				/* var _this=this;
				_this.$router.push({
					path: '/category',
					force: true
				}) */
			},
			getsupervision() {
				var _this = this;
				var params
				this.$mmapi.get('drugs/getSupervisionClass', params).then(function(res) {
					//数据处理
					if(res.code==200){
						_this.supervision_class=res.data;
					}
				})
			},
			//商品详情
			clickgoodsDetail(uid, type) {
				this.$router.push({
					path: '/goodsDetail',
					query: {
						uid: uid,
						type: type
					}
				})
			},
			//当前打开
			handleChange(index, secIndex) {
				console.log(index);
				console.log(secIndex);
				this.typeIndex = index
				this.typeSubIndex = secIndex
				//this.setformobj.typeId = this.menuList[index].id
				this.setformobj.typeSubId = this.menuList[index].sonType[secIndex].id
				this.searchclick()
			},
			//跳转页面
			handleCurrentChange(currentPage) {
				//console.log(currentPage)
				this.currentPage = currentPage
				this.searchclick()
			},
			//获取商品类型
			getSecondPage() {
				var _this = this;
				var params = {
					currentPage: 1,
					pageSize: 888,
				};
				this.$http.post('medicine/medicineDrugsType/getPage', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var records = res.data.records;
						//查看是否携带栏目id
						if (_this.setformobj.typeId) {
							var findfirst = records.findIndex((item) => {
								return item.id == _this.setformobj.typeId; //筛选出匹配数据
							});
							if (findfirst > -1) {
								_this.activeNames.push(findfirst); //展开
								_this.typeIndex = findfirst
								if (_this.setformobj.typeSubId) {
									var findsec = records[findfirst].sonType.findIndex((item) => {
										return item.id == _this.setformobj.typeSubId; //筛选出匹配数据
									});
									if (findsec > -1) {
										_this.typeSubIndex = findsec
									}
								}

							}
						}
						//赋值
						_this.menuList = records
						//查询商品
						_this.searchclick()
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//获取品牌
			getBrandPage() {
				var _this = this
				var params = {}
				params["currentPage"] = 1
				params["pageSize"] = 888
				this.$http.post('medicine/medicineDrugsBrand/getPage', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var records = res.data.records;
						_this.brandList = records
					}
				})
			},
			selectBrand(uid) {
				this.setformobj.brand = uid
				this.searchclick()
			},
			//获取生产厂家
			getfactoryPage() {
				var _this = this
				var params = {}
				params["currentPage"] = 1
				params["pageSize"] = 888
				this.$http.post('medicine/medicineDrugsManufacturer/getPage', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var records = res.data.records
						_this.factoryList = records
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//选择厂家
			selectFactory(uid) {
				this.setformobj.manufacturer = uid
				this.searchclick()
			},
			//选择价格
			handlePricelist(index, min, max) {
				this.setformobj.priceIndex = index
				if (max == "max") {
					this.setformobj.minPrice = min //最低价
					this.setformobj.maxPrice = null //最低价
				} else {
					this.setformobj.minPrice = min //最低价
					this.setformobj.maxPrice = max //最高价
				}
				this.searchclick()
			},
			priceSearch() {
				this.setformobj.priceIndex = null
				if (this.setformobj.minPrice) {
					this.setformobj.minPrice = Number(this.setformobj.minPrice)
				}
				if (this.setformobj.maxPrice) {
					this.setformobj.maxPrice = Number(this.setformobj.maxPrice)
				}
				this.searchclick()
			},
			//选择标签
			selectTags(index) {
				var tagobj = this.tagList[index]
				tagobj.isselect = !tagobj.isselect
				this.tagList[index] = tagobj
				this.searchclick()
			},
			//排序
			handleSort(sortNum) {
				if (this.setformobj.sortIndex == 2) {
					this.setformobj.sortIndex = 3
				} else {
					this.setformobj.sortIndex = sortNum
				}
				this.searchclick()
			},
			//搜索查询
			searchclick() {
				var _this = this
				//处理标签
				var tags = []
				for (var i = 0; i < this.tagList.length; i++) {
					if (this.tagList[i].isselect) {
						tags.push(this.tagList[i].name)
					}
				}
				this.setformobj.tags = tags
				var params = this.setformobj
				params["currentPage"] = this.currentPage
				params["pageSize"] = 16
				params["keyword"] = this.keyword
				params["priceAsc"] = null //价格排序 true为升序
				params["saleAsc"] = null //销量排序 true为升序
				//综合排序
				if (this.setformobj.sortIndex == 2 || this.setformobj.sortIndex == 3) {
					if (this.setformobj.sortIndex == 3) {
						//升序
						params["priceAsc"] = true
					} else {
						params["priceAsc"] = false
					}
				}
				//销量
				if (this.setformobj.sortIndex == 1) {
					//降序
					params["saleAsc"] = false
				}
				this.$http.post('medicine/medicineDrugsSku/getPage', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var records = res.data.records
						_this.total = res.data.total
						records.forEach((item, index) => {
							// if(item.finalPrice){
							// 	//分转元
							// 	item["finalPrice"] = (Number(item.price)/100).toFixed(2)
							// 	item["oriPrice"] = (Number(item.oriPrice)/100).toFixed(1)
							// }
							if (item.medicineDrugs.mainImages && item.medicineDrugs.mainImages.length >
								0) {
								item["avatar"] = item.medicineDrugs.mainImages[0]
							} else {
								item["avatar"] = ""
							}
							item["goodsName"] = _this.join(item.goodsName, _this.keyword)
							item["supervision_class"] = _this.supervision_class[item.medicineDrugs.typeId]
						})
						_this.searchList = records
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			// 拼接
			join(str, key) {
				var reg = new RegExp((`(${key})`), "gm");
				var replace = '<span style="color:#FF2120;font-weight:bold;">$1</span>';
				return str.replace(reg, replace);
			},

		}
	};
</script>
<style lang="scss" scoped></style>